import { render, staticRenderFns } from "./errorPage.vue?vue&type=template&id=279f6419&scoped=true"
import script from "./errorPage.vue?vue&type=script&lang=js"
export * from "./errorPage.vue?vue&type=script&lang=js"
import style0 from "./errorPage.vue?vue&type=style&index=0&id=279f6419&prod&rel=stylesheet%2Fscss&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "279f6419",
  null
  
)

export default component.exports